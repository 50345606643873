@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.merriweather{
  font-family: "Merriweather", serif;
  font-style: normal;
}


.indus {
  font-family: "Fjalla One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.yeseva{
  font-family: "Yeseva One", serif;
  font-style: normal;
}

.falija {
  font-family: "Fjalla One", serif;
  font-style: normal;
}


@font-face {
  font-family: "BodoniBT-Book";
  src: local("BodoniBT-Book");
  font-weight: normal;
  font-style: normal;
}
.animate__zoomIn:hover {
  animation: myAnim 0.5s ease-in-out 0s 1 normal forwards;
}
@keyframes myAnim {
  0% {
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    transform: scale(1);
  }

  100% {
    /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); */
    transform: scale(1.03);
  }
}

.animate__zoomIn2:hover {
  animation: myAnim2 0.5s ease-in-out 0s 1 normal forwards;
}
@keyframes myAnim2 {
  0% {
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    transform: scale(1);
  }

  100% {
    /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); */
    transform: scale(1.3);
  }
}

/* Define the animation for desktop view */
@media only screen and (min-width: 768px) {
  .animate__zoomIn3:hover {
    animation: myAnim3 0.5s ease-in-out 0s 1 normal forwards;
  }
}

/* Keyframes for the animation */
@keyframes myAnim3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.13);
  }
}

/* Add this to your global styles or within the component's style block */
/* @keyframes moveText {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    90% {
      opacity: 1;
    }

    100% {
      transform: translateX(50%);
      opacity: 0;
    }
  }
   */
/* Add the 'moving-text' class to apply the animation */
/* .moving-text {
    animation: moveText 5s linear infinite; /* You can adjust the duration and timing function as needed */
/* animation-fill-mode: forwards;
  /* } */

.social-icons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 30;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@keyframes moveText {
  0% {
    transform: translateX(100%); /* Start from the right */
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(0); /* Move to the left */
    opacity: 1;
  }
}

.moving-text {
  animation: moveText 15s linear infinite; /* You can adjust the duration and timing function as needed */
  animation-fill-mode: forwards;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .moving-text {
    font-size: 20px; /* Adjust font size for smaller screens */
    animation: moveText 10s linear infinite; /* Adjust animation duration for smaller screens */
    transform: translateX(0); /* Ensure text starts from left on mobile */
  }
}

.slick-prev {
  z-index: 1000;
  margin-left: 40px;
  color: #f5f5f5;
  ::before {
    background-color: #f5f5f5;
  }
}

.slick-next {
  z-index: 1000;
  margin-right: 40px;
  color: #f5f5f5 !important;
}
.slick-next:before,
.slick-prev:before {
  opacity: unset !important;
  font-size: 22px;
}

.slick-dots {
  position: absolute;
  bottom: 40px !important;
}

.slick-dots li {
  margin: 0px !important;
}

.slick-dots li button:before {
  color: gray !important;
  font-size: 10px !important;
  padding: 0px !important;
  opacity: unset !important;
}

@media screen and (max-width: 768px) {
  .slick-prev {
    margin-left: 30px;
  }

  .slick-next {
    margin-right: 30px;
  }
  .slick-dots {
    display: none !important;
  }
}

.slick-dots li.slick-active button:before {
  color: white !important;
  font-size: 10px !important;
  padding: 0px !important;
  opacity: unset !important;
}


.card {
  backdrop-filter: blur(2px) saturate(100%);
    -webkit-backdrop-filter: blur(0px) saturate(100%);
    background-color: rgba(248, 255, 252, 0.6);
    border: 10px solid rgba(255, 255, 255, 0);
}


.card2 {
  backdrop-filter: blur(2px) saturate(100%);
    -webkit-backdrop-filter: blur(0px) saturate(100%);
    background-color: rgba(0, 118, 67, 0.716);
    border: 10px solid rgba(255, 255, 255, 0);
}



/* counter */

.space-area {
  height: 850px;
  background: #009a11;
  padding: 100px 0;
  position: relative;
}

.space-area h1 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
}

.count-area {
  position: relative;
  background: #009aaa;
  padding: 100px 0;
  text-align: center;
  color: #fff;
}

.count-area-content {
  margin-top: 30px;
}

.count-area i {
  font-size: 48px;
}

.count-digit {
  margin: 20px 0px;
  color: #009a11;
  font-weight: 700;
  font-size: 50px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.count-title {
  font-size: 20px;
}

.counter-loaded {
  opacity: 1;
}


.bgmc{
  background-image: url("https://i.pinimg.com/1200x/32/ea/03/32ea039c666ae099490463cb7f1169cb.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}





/* button deisgn  */



.swiper-slide:not(.swiper-slide-prev):not(.swiper-slide-active):not(.swiper-slide-next) {
  opacity: 0;
}

.swiper-slide-prev, 
.swiper-slide-next {
  opacity: 0.3;
}

.swiper-slide-active {
  transition: all 0.3s;
  opacity: 1;
  background-color: orange !important;
}


.oig-image-gallery .oig-img-box img{
  object-fit: cover;
}


.bgimgcus{
  /* background-image: url(); */
  background-position: center;
  background-size: cover;
}



@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}




/* LEAF ANIMATION  */


/* .ani{
  height:100%;
  width:100%;
  }
  .leaf{
      position:absolute ;
      width:100%;
      height:100%;
      top:0;
      left:0;
  }
  .leaf div{
  position:absolute ;
  display:block ;
  }
  .leaf div:nth-child(1){
      left:20%; 
      animation:fall 15s linear infinite ;
      animation-delay:-2s;
  
  }
  .leaf div:nth-child(2){
      left:70%; 
      animation:fall 15s linear infinite ;
      animation-delay:-4s;
  }
  .leaf div:nth-child(3){
      left:10%; 
      animation:fall 20s linear infinite ;
      animation-delay:-7s;
      
  }
  .leaf div:nth-child(4){
      left:50%; 
     animation:fall 18s linear infinite ; 
     animation-delay:-5s;
  }
  .leaf div:nth-child(5){
      left:85%; 
      animation:fall 14s linear infinite ;
      animation-delay:-5s;
  }
  .leaf div:nth-child(6){
      left:15%; 
      animation:fall 16s linear infinite ;
      animation-delay:-10s;
  }
  .leaf div:nth-child(7){
      left:90%; 
      animation:fall 15s linear infinite ;
      animation-delay:-4s;
  }
  
  @keyframes fall{
      0%{
          opacity:1;
          top:-10%;
          transform:translateX (20px) rotate(0deg);
      }
      20%{
          opacity:0.8;
          transform:translateX (-20px) rotate(45deg);
      }
      40%{
  
          transform:translateX (-20px) rotate(90deg);
      }
      60%{
          
         transform:translateX (-20px) rotate(135deg); 
      }
      80%{
      
          transform:translateX (-20px) rotate(180deg);
      }
      100%{
          
          top:110%;
          transform:translateX (-20px) rotate(225deg);
      }
      }
  .leaf1{
      transform: rotateX(180deg);
  }
  h2{
      position:absolute ;
      top:40%;
      width:100%;
      font-family: 'Courgette', cursive;
      font-size:4em;
      text-align:center;
      transform:translate ;
      color:#fff;
      transform:translateY (-50%);
  } */




#resize-effect {
  font-size: 20px;
  font-weight: 100;
  animation: resize-anime 5s forwards;
  animation-direction: alternate;
}
#resize-effect span > span:nth-child(odd) {
}
  
@keyframes resize-anime {
  from {
    font-size: 20px;
    font-weight: 400;
    opacity:0
  } 
  to {
    font-size: 40px;
    font-weight: 400;
    text-shadow: 0px 0px 5px white;
    opacity:1
  }
}
