.landing-page {
    background-image: url("https://d57jfigkzby0a.cloudfront.net/banner.jpg");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    position: relative;
    justify-content: flex-end;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  
  .landing-heading {
    font-size: 40px;
    font-weight: bold;
    font-family: "Outfit";
    color: #5c6723;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), 2px 2px 3px rgba(0, 0, 0, 0.2);
  }
  
  .landing-sub-heading {
    font-size: 30px;
    margin: 0;
    font-weight: bold;
    font-family: "Outfit";
    color: #5c6723;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), 2px 2px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  
  .button-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .text-container {
    max-width: 500px;
    background-color: rgba(57, 57, 57, 0.4);
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .landing-ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
  }
  
  .landing-li {
    margin-bottom: 10px;
    font-size: 20px;
    font-family: "Outfit";
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), 2px 2px 3px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .landing-page {
      align-items: center;
    }
    .landing-heading {
      font-size: 30px;
    }
    .landing-sub-heading {
      font-size: 24px;
    }
  
    .button-container {
      flex-direction: row;
      gap: 10px;
      position: relative;
      top: unset;
      right: unset;
    }
  
    .social-icons {
      right: 10px;
    }
  
    .text-container {
      width: 80%;
      padding: 10px;
      align-self: center;
      margin-left: unset;
      margin-right: 50px;
    }
  
    li {
      font-size: 16px;
    }
  }